<!-- 劳动合同 > 历史劳动合同 -->
<template>
  <div class="projectSubitem" v-loading="loading">
    <UploadList v-model="uploadData" fileCode="agreement_fj" :ywDataId="manageId"></UploadList>
    <div class="fullScreenOperation">
      <el-button type="info" @click="re">返回</el-button>
    </div>
  </div>
</template>
<script>
export default {
  components: {
    UploadList: () => import("./AgreementList.vue"),
  },
  inject: ["re"],
  props: {
    manageId: {},
  },
  data() {
    return {
      loading: false,
      saveloading: false,
      uploadData: [],
    };
  },
  watch: {
    manageId: {
      immediate: true,
      handler: function(a) {
        if (a) {
          this.getData();
        }
      },
    },
  },
  created() {},
  methods: {
    getData() {
      this.$api.user
        .getLaborContract(this.manageId)
        .then((res) => {
          this.uploadData = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/config.scss";
</style>
